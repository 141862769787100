import React, { useEffect, useState } from 'react';
import styles from './AddPayment.module.css';

import AcceptIcon from '../static_files/AcceptOrder.svg';
import KraIcon from '../static_files/KRA.svg';
import VenmoIcon from '../static_files/Venmo.svg';
import ZelleIcon from '../static_files/Zelle.svg';
import AlipayIcon from '../static_files/Alipay.svg';
import WireIcon from '../static_files/Wire.svg';
import USDIcon from '../static_files/usd.svg';
import expandSvg from '../static_files/arrowRight.svg';
import expandHoverSvg from '../static_files/arrowRight Hover.svg';
import AddPaymentInputInfo from './AddPaymentInputInfo';

// Map method_name to the correct icon
const iconMap = {
  'Bank Wire': WireIcon,
  'Cash - Paper': USDIcon,
  'Zelle': ZelleIcon,
  'Venmo': VenmoIcon,
  'Alipay': AlipayIcon,
};

function AddPayment({ onClose }) {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [showModal, setShowModal] = useState(false);
    const [showAddPayment, setShowAddPayment] = useState(true);

  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  // When user clicks a row
  const handlePaymentRowClick = (row) => {
    setSelectedRow(row);
    setSelectedPaymentMethod(row.method_name)
    setShowModal(true);
      // onClose();
    setShowAddPayment(false);

  };

  // Called when user clicks "Confirm" in the modal (unused now that child does fetch)
  const handleConfirm = async (formData) => {
    console.log('Confirm Data:', formData);
    try {
      const response = await fetch('/api/payment-method', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Request failed');
      }

      // If needed, update local state or re-fetch data here
    } catch (err) {
      console.error('Error saving payment method:', err);
    }

    // Close the modal after saving
    setShowModal(false);
  };

  // Fetch payment methods from backend
  useEffect(() => {
    async function fetchPaymentMethods() {
      try {
        const res = await fetch(
          'https://max1-funding-arb.uc.r.appspot.com/available_payment_method'
        );
        const data = await res.json();
        const formatted = data.map((item) => ({
          ...item,
          icon: iconMap[item.method_name] || USDIcon, // fallback icon
        }));
        setPaymentMethods(formatted);
      } catch (error) {
        console.error('Error fetching payment methods:', error);
      }
    }
    fetchPaymentMethods();
  }, []);

  return (
    <div className={styles.modalOverlay}>
      {showAddPayment && (
      <div className={styles.modalContainer}>
        <div className={styles.headerRow}>
          <h2 className={styles.title}>Add a payment method</h2>
          <button className={styles.closeBtn} onClick={onClose}>
            &times;
          </button>
        </div>

        <div className={styles.dividerLine} />

        <div className={styles.paymentList}>
          {paymentMethods.map((method, index) => (
            /* Single container div for each row, with a unique key */
            <div key={method.id || index}>
              <div
                className={styles.paymentRow}
                onClick={() => handlePaymentRowClick(method)}
              >
                {/* Left part: Icon + method name + details */}
                <div className={styles.leftPart}>
                  <img
                    src={method.icon}
                    alt={method.method_name}
                    className={styles.iconStyle}
                  />
                  <div>
                    <div className={styles.methodName}>{method.method_name}</div>
                    <div className={styles.details}>
                      ETA: {method.processing_time}
                      <br />
                      Fees: {method.fees}
                    </div>
                  </div>
                </div>

                {/* Right part: limit + label + arrow */}
                <div className={styles.rightPart}>
                  <div>
                    <div className={styles.limitText}>{method.amount_limit}</div>
                    <div className={styles.amountLabel}>Amount limit</div>
                  </div>
                  <img
                    src={expandSvg}
                    alt="expand"
                    className={styles.expandIcon}
                    onMouseEnter={(e) => {
                      e.currentTarget.src = expandHoverSvg;
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.src = expandSvg;
                    }}
                  />
                </div>
              </div>

              {/* Divider line between rows, skip it after the last row */}
              {index < paymentMethods.length - 1 && (
                <div className={styles.rowDivider} />
              )}
            </div>
          ))}
        </div>

        <div className={styles.footerRow}>
          Learn about our buy/sell fees{' '}
          <a href="/fees" target="_blank" rel="noreferrer">
            here
          </a>
          .
        </div>
      </div>)}

      {/* Show the modal if user clicked a row */}
      {showModal && (
        <AddPaymentInputInfo
          rowId={selectedRow?.id}
          onClose={() => setShowModal(false)}
          onConfirm={handleConfirm} /* still passed, but no longer used by child */
            paymentMethod={selectedRow.method_name}
        />
      )}
    </div>
  );
}

export default AddPayment;
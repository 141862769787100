import React, {useContext, useEffect, useState} from 'react';
import styles from './AddPaymentInputInfo.module.css';
import {useAuth} from "../../AuthContext";
import {SwapMainAppContext} from "../../SwapMainAppContext";

function AddPaymentInputInfo({
  onClose,
  onConfirm,
  paymentMethod,

}) {
  // State for *all* possible columns in your payment_info table.
  // Even if a particular payment method doesn’t use them, you can still send them as null.
  const [recipientName, setRecipientName] = useState('');
  const [recipientAddress, setRecipientAddress] = useState('');
  const [bankCountry, setBankCountry] = useState('');
  const [bankName, setBankName] = useState('');
  const [bankAddress, setBankAddress] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [swiftBicCode, setSwiftBicCode] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [deliveryAddress, setDeliveryAddress] = useState('');
  const[email,setEmail] = useState('');
  const [userEmailAddress, setUserEmailAddress ] = useState('');
    const [userWalletAddress, setUserWalletAddress ] = useState('');


  const [errorMsg, setErrorMsg] = useState('');
   const {walletAddress} = useContext(SwapMainAppContext);
    const {gatewayUserData} = useAuth();
    useEffect(()=>{
        if (gatewayUserData){
            setUserEmailAddress(gatewayUserData.email)
        }
        if(walletAddress){
            setUserWalletAddress(walletAddress)
        }
    },[walletAddress,gatewayUserData])
  const handleConfirm = async () => {
    // Basic method‐specific validation:
    switch (paymentMethod) {
      case 'BANK WIRE':
        // For a Bank Wire, require the bank‐related fields:
        if (!recipientName || !recipientAddress || !bankCountry ||
            !bankName || !bankAddress || !accountNumber) {
          setErrorMsg('Please fill in all required fields for Bank Wire.');
          return;
        }
        break;

      case 'Cash - Paper':
        // For CASH‐Paper, require mobile number + delivery address, etc.
        if (!mobileNumber || !deliveryAddress) {
          setErrorMsg('Please provide Mobile Number and Delivery Address for CASH-Paper.');
          return;
        }
        break;

      case 'Venmo':
        // For Venmo, require recipientName + wallet address (UUID).
        if (!recipientName || !userWalletAddress) {
          setErrorMsg('Please provide Recipient Name and make sure walletAddress is filled for Venmo.');
          return;
        }
        break;

      case 'Zelle':
        // For Zelle, require recipientName, email, mobile
        if (!recipientName || !email || !mobileNumber) {
          setErrorMsg('Please fill in Recipient Name, Email, and Mobile for Zelle.');
          return;
        }
        break;

      case 'Alipay':
        // For Alipay, require recipientName, email, mobile
        if (!recipientName || !email || !mobileNumber) {
          setErrorMsg('Please fill in Recipient Name, Email, and Mobile for Alipay.');
          return;
        }
        break;

      default:
        // If no recognized method, just skip or show error
        setErrorMsg('Unknown payment method.');
        return;
    }

    // Build up the payload to send to your backend.
    // Many of these columns will be null if they are not used by the given method.
    // userEmailAddress or userWalletAddress can identify the logged-in user.
    const payload = {
      payment_method: paymentMethod,
      email_address: userEmailAddress || null,
      wallet_address: userWalletAddress || null,

      // Payment‐info columns:
      recipient_name: recipientName || null,
      recipient_address: recipientAddress || null,
      bank_country: bankCountry || null,
      bank_name: bankName || null,
      bank_address: bankAddress || null,
      account_number: accountNumber || null,
      swift_bic_code: swiftBicCode || null,
      us_routing_no: routingNumber || null,
      email: email || null,
      mobile_number: mobileNumber || null,
      delivery_address: deliveryAddress || null,
      // amount_limit: (You could add it if needed)
    };

    try {
      // For example, if your backend endpoint is:
      // const response = await fetch('https://your-backend-url/payment-method', {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify(payload),
      // });
      // if (!response.ok) {
      //   throw new Error('Failed to save. Please try again.');
      // }
        console.log("payload!!! here", payload)
      onClose();
    } catch (err) {
      setErrorMsg(err.message || 'Failed to save. Please try again.');
    }
  };

  // Conditionally render the form fields for each method:
  const renderFields = () => {
    switch (paymentMethod) {
      case 'Bank Wire':
        return (
          <>
            <label>Recipient name</label>
            <input
              type="text"
              value={recipientName}
              onChange={(e) => setRecipientName(e.target.value)}
            />
            <label>Recipient address</label>
            <input
              type="text"
              value={recipientAddress}
              onChange={(e) => setRecipientAddress(e.target.value)}
            />
            <label>Bank country</label>
            <input
              type="text"
              value={bankCountry}
              onChange={(e) => setBankCountry(e.target.value)}
            />
            <label>Bank name</label>
            <input
              type="text"
              value={bankName}
              onChange={(e) => setBankName(e.target.value)}
            />
            <label>Bank address</label>
            <input
              type="text"
              value={bankAddress}
              onChange={(e) => setBankAddress(e.target.value)}
            />
            <label>Account number</label>
            <input
              type="text"
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
            />
            <label>SWIFT BIC code</label>
            <input
              type="text"
              value={swiftBicCode}
              onChange={(e) => setSwiftBicCode(e.target.value)}
            />
            <label>Routing number</label>
            <input
              type="text"
              value={routingNumber}
              onChange={(e) => setRoutingNumber(e.target.value)}
            />
          </>
        );

      case 'Cash - Paper':
        return (
          <>
            <label>Mobile number</label>
            <input
              type="text"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
            />
            <label>Delivery address</label>
            <input
              type="text"
              value={deliveryAddress}
              onChange={(e) => setDeliveryAddress(e.target.value)}
            />
          </>
        );

      case 'Venmo':
        return (
          <>
            <label>Recipient name</label>
            <input
              type="text"
              value={recipientName}
              onChange={(e) => setRecipientName(e.target.value)}
            />
            <label>Wallet address (UUID)</label>
            {/* Typically for Venmo, you might just send userWalletAddress as a prop */}
            <div>
              <strong>Wallet:</strong> {userWalletAddress || 'No wallet address provided'}
            </div>
          </>
        );

      case 'Zelle':
        return (
          <>
            <label>Recipient name</label>
            <input
              type="text"
              value={recipientName}
              onChange={(e) => setRecipientName(e.target.value)}
            />
            <label>Email</label>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label>Mobile number</label>
            <input
              type="text"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
            />
          </>
        );

      case 'Alipay':
        return (
          <>
            <label>Recipient name</label>
            <input
              type="text"
              value={recipientName}
              onChange={(e) => setRecipientName(e.target.value)}
            />
            <label>Email</label>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label>Mobile number</label>
            <input
              type="text"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
            />
          </>
        );

      default:
        return <div>Invalid Payment Method</div>;
    }
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContainer}>
        <div className={styles.headerRow}>
          <h2 className={styles.title}>Add a payment method</h2>
          <button className={styles.closeBtn} onClick={onClose}>
            &times;
          </button>
        </div>

        <div className={styles.dividerLine} />

        {errorMsg && <div className={styles.errorMsg}>{errorMsg}</div>}

        {/* Render fields dynamically based on paymentMethod */}
        {renderFields()}

        <button className={styles.confirmBtn} onClick={handleConfirm}>
          Confirm
        </button>
      </div>
    </div>
  );
}

export default AddPaymentInputInfo;
export const jsonToCSV = (json) => {
    const csvRows = [];
    const headers = Object.keys(json[0]);

    csvRows.push(headers.join(','));

    for (const row of json) {
        const values = headers.map(header => {
            const value = row[header];
            const escapedValue = ('' + value).replace(/"/g, '\\"');
            return `"${escapedValue}"`;
        });
        csvRows.push(values.join(','));
    }

    return csvRows.join('\n');
};

export const digitFormat = (number) => {
    return Number(number).toLocaleString('en-US')
}

export const moneyFormat = (num) => {
    const factor = 10 ** 2; // 2 decimals
    const floored = Math.floor(Number(num) * factor) / factor;

    return floored.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};

export const moneyFormat4decimals = (num) => {
    const factor = 10 ** 4; // 4 decimals
    const floored = Math.floor(Number(num) * factor) / factor;

    return floored.toLocaleString('en-US', {
        minimumFractionDigits: 4,
        maximumFractionDigits: 4,
    });
};

export function shortenAddress(address, startLength = 4, endLength = 4) {
    if (!address) return '';
    if (address.length <= startLength + endLength) {
        return address;
    }
    const start = address.slice(0, startLength);
    const end = address.slice(-endLength);
    return `${start}..${end}`;
}

// A basic validator for each contact field.
export function check_input(fieldName, value) {
    console.log("field name, value", fieldName, value)
    if (value === '--') {
        return true
    }
    switch (fieldName) {
        case 'email':
            return /\S+@\S+\.\S+/.test(value);

        case 'twitter':
            if (!value) return false;
            return /^[A-Za-z0-9_]+$/.test(value.replace(/^@/, ''));

        case 'phone':
            return /^[\d\s\-\+()]+$/.test(value);

        case 'telegram':
            if (!value) return false;
            return /^[A-Za-z0-9_]+$/.test(value.replace(/^@/, ''));

        default:
            return true;
    }
}


export async function fetchC2CNetworkConfig() {
  try {
    const response = await fetch(
      'https://usdi-api-256611876551.europe-west1.run.app/c2c_redeem_config_solana'
    );
    if (!response.ok) {
      throw new Error('Failed to fetch c2c_redeem_config_solana');
    }
    const data = await response.json();
    if (data){return data[0];}

  } catch (error) {
    console.error('Error fetching network fee:', error);
    return null;
  }
}

export async function createWallet(currentUserData) {
  try {
    const response = await fetch(
      'https://usdi-api-256611876551.europe-west1.run.app/email_user_create_wallet',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: currentUserData.email
        })
      }
    );
    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }
    const data = await response.json();
    console.log('Wallet creation response:', data);

  } catch (error) {
    console.error('Error creating wallet:', error);
  }
}

export    function formatAmount(value) {
        const num = parseFloat(value);
        if (isNaN(num)) return value;
        return num.toString();
    }

export    const handleShare = (e, address) => {
        e.stopPropagation();
        window.open(`https://solscan.io/token/${address}`, "_blank");
    };
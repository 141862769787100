import React, { createContext, useEffect, useState } from 'react';
import { fetchC2CNetworkConfig } from './tables/utils';

export const SwapMainAppContext = createContext(null);

export function SwapMainAppProvider({ children }) {
  const [notification, setNotification] = useState({
    status: null,
    message: null,
  });

    // for error message
  function showNotification(status, message) {
    setNotification({ status, message });
    setTimeout(() => {
      setNotification({ status: null, message: null });
    }, 5000);
  }

  // --- for data config from backend
  const [networkFeePercent, setNetworkFeePercent] = useState(0);
  const [collateralRatio, setCollateralRatio] = useState(0);

  useEffect(() => {
    async function loadFee() {
      try {
        const feePercent = await fetchC2CNetworkConfig();
        setNetworkFeePercent(feePercent.network_fee_percentage);
        setCollateralRatio(feePercent.collateral_ratio);
      } catch (err) {
        console.error('Error fetching network fee:', err);
      }
    }
    loadFee();
  }, []);

  // --- store the  walletAddress, email,
  // Initialize from localStorage if available
  const [walletAddress, setWalletAddress] = useState(() => {
    return localStorage.getItem('walletAddress') || '';
  });
  // const [email, setEmail] = useState(() => {
  //   return localStorage.getItem('email') || '';
  // });

  // Whenever walletAddress changes, sync to localStorage
  useEffect(() => {
    if (walletAddress) {
      localStorage.setItem('walletAddress', walletAddress);
    } else {
      localStorage.removeItem('walletAddress');
    }
  }, [walletAddress]);

  // // Whenever email changes, sync to localStorage
  // useEffect(() => {
  //   if (email) {
  //     localStorage.setItem('email', email);
  //   } else {
  //     localStorage.removeItem('email');
  //   }
  // }, [email]);

  // A helper to clear both walletAddress and email in one go (logout/disconnect)
  function disconnect() {
    setWalletAddress('');
    // setEmail('');
  }

  // --- Provide everything in context ---
  return (
    <SwapMainAppContext.Provider
      value={{
        notification,
        showNotification,
        networkFeePercent,
        collateralRatio,
        walletAddress,
        setWalletAddress,
        // email,
        // setEmail,
        disconnect,
      }}
    >
      {children}
    </SwapMainAppContext.Provider>
  );
}
// TransactionHistory.jsx
import React, {useContext, useEffect, useState} from 'react';
import styles from './TransactionHistory.module.css';
import ConnectWalletHeader from "./header/ConnectWalletHeader";
import LeftPanel from "./SwapLeftPanel/LeftPanel";
import {useNavigate} from 'react-router-dom';

// Icons
import greyCircleSvg from './static_files/circle.svg';
import arrowUpSvg from './static_files/Vector Up.svg';
import arrowDownSvg from './static_files/Vector Down.svg';
import expandSvg from "./static_files/expand.svg";
import expandHoverSvg from "./static_files/expandHover.svg";
import foldSvg from "./static_files/unexpand.svg";
import foldHoverSvg from "./static_files/unexpandHover.svg";
import uIconSvg from "./static_files/USDi.png";

import copyImg from "./static_files/copy-selector.svg";
import copyHoverImg from "./static_files/copy-selector-hover.svg";
import copyConfirmImg from "./static_files/checkmark.png";

import shareImg from "./static_files/share-selector.svg";
import shareHoverImg from "./static_files/share-selector-hover.svg";

import timeline1Img from "./static_files/timeline1.svg";
import timeline2Img from "./static_files/timeline2.svg";
import timeline3Img from "./static_files/timeline3.svg";
import noTransactionImg from "./static_files/No transaction.svg"
import {shortenAddress, formatAmount} from './utils';

// Import CSSTransition for animation
import {CSSTransition} from 'react-transition-group';
import {SwapMainAppContext} from "../SwapMainAppContext";

import C2COrderDetail from "./C2C NetWork/C2COrderDetail";

function TransactionHistory() {
    // for error messages
    const {showNotification} = useContext(SwapMainAppContext);
    const navigate = useNavigate();

    const [walletAddress, setWalletAddress] = useState("");
    const [groupedTransactions, setGroupedTransactions] = useState([]);
    const [expanded, setExpanded] = useState({});
    // New state variable to trigger a re-fetch after updates
    const [refetchFlag, setRefetchFlag] = useState(0);
    const [showPanel, setShowPanel] = useState(false);


    // 新增订单详情相关状态（与 C2CNetwork 类似）
    const [showCashReceivedConfirm, setShowCashReceivedConfirm] = useState(false);
    const [kiraPrice, setKiraPrice] = useState(null);
    const [selectedTx, setSelectedTx] = useState(null);
    const [orderDetails, setOrderDetails] = useState(null);
    const [txTrigger, setTxTrigger] = useState(0);
    const [sendKiraAmount, setSendKiraAmount] = useState(0);
    // used for pass in the top right button to show the wallet address
    const [emailWalletAddress, setEmailWalletAddress] = useState(null);

    // Fetch and group transactions by date
    useEffect(() => {
        if (!walletAddress) return;

        const formatLocalDate = (dateStr) => {
            const dateObj = new Date(dateStr);
            if (isNaN(dateObj.getTime())) return dateStr;
            return dateObj.toLocaleString([], {
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: '2-digit',
                hour12: true,
            });
        };

        // 从user transaction history获取 data并且 process the data
        // data 有data.transaction_history and data.cash_redeem
        async function fetchUserHistory() {
            try {
                const url = `https://usdi-api-256611876551.europe-west1.run.app/cash_redeem/get_user_history?user_address=${walletAddress}`;
                const res = await fetch(url);
                const data = await res.json();
                console.log("history here", data)
                if (!data || !Array.isArray(data.transaction_history)) {
                    console.warn("No valid transaction_history returned");
                    setGroupedTransactions([]);
                    return;
                }

                const cashRedeemData = Array.isArray(data.cash_redeem) ? data.cash_redeem : [];

                // Map each transaction
                const allTxs = data.transaction_history.map((tx) => {
                    const createdAt = new Date(tx.txs_created_timestamp + 'Z');
                    const timeStr = createdAt.toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                    });
                    const dateStr = createdAt.toLocaleDateString([], {month: 'long', day: 'numeric'});

                    const arrowDirection = tx.txs_type?.toLowerCase() === 'mint' ? 'down' : 'up';

                    // If redeem with method cash, display as "C2C"
                    let displayType = tx.txs_type;
                    if (tx.txs_type?.toLowerCase() === 'redeem' && tx.method?.toLowerCase() === 'cash') {
                        displayType = 'C2C';
                    }

                    // Build the initial transaction object.
                    // Notice we are not using any status value from transaction_history.

                    const txObj = {
                        id: tx.txs_id,
                        arrowDirection,
                        type: displayType,
                        amount: formatAmount(tx.from_token_amount || 0),
                        address: tx.from_address || '',
                        chain: tx.chain?.toLowerCase() || '',
                        redeemFrom: formatAmount(tx.from_token_amount || 0),
                        timeStr,
                        dateStr,
                        createdAt, // used for sorting
                        cash_delivery_received_time: "--",
                        usdi_released_time: "--",
                        status: tx.status && tx.status.toLowerCase() === 'complete'
                            ? 'Completed'
                            : tx.status && tx.status.toLowerCase() === 'pending'
                                ? 'Pending'
                                : tx.status,
                        fulfill_deadline: tx.fulfill_deadline || "--",
                        collateral_ratio: 2, // Hard-coded for now
                    };

                    // Find the matching cash_redeem record by txs_id.
                    const matchingRedeem = cashRedeemData.find((redeem) => redeem.txs_id === tx.txs_id);
                    const statusMapping = {
                        'order_created': 'Order Created',
                        'order_fulfilled': 'USDi Released',
                        'cash_received': 'Cash Delivered',
                        'completed': 'Completed',
                        'pending': 'Pending'
                    };

                    if (matchingRedeem) {
                        // Convert ISO timestamp strings to local time using formatLocalDate
                        txObj.usdi_released_time = matchingRedeem.usdi_released_time
                            ? formatLocalDate(matchingRedeem.usdi_released_time)
                            : "--";
                        txObj.cash_delivery_received_time = matchingRedeem.cash_delivery_received_time
                            ? formatLocalDate(matchingRedeem.cash_delivery_received_time)
                            : "--";
                        // Use the status exclusively from cash_redeem (if needed, adjust as required)
                        txObj.node_operator_wallet = matchingRedeem.node_operator_wallet;
                        console.log("status", matchingRedeem.status.toLowerCase())
                        txObj.status = statusMapping[matchingRedeem.status.toLowerCase()] || matchingRedeem.status;
                    }

                    return txObj;
                });

                // Group transactions by the formatted date string
                const groupedMap = {};
                allTxs.forEach((tx) => {
                    if (!groupedMap[tx.dateStr]) {
                        groupedMap[tx.dateStr] = [];
                    }
                    groupedMap[tx.dateStr].push(tx);
                });

                // Sort transactions within each day (newest at the top)
                Object.keys(groupedMap).forEach((dateKey) => {
                    groupedMap[dateKey].sort((a, b) => b.createdAt - a.createdAt);
                });

                // Convert the grouped map into an array.
                // Also sort the groups by the most recent transaction (newest day first).
                const groupedArr = Object.keys(groupedMap)
                    .map((dateKey) => {
                        // Use the createdAt of the first transaction in the sorted array as representative.
                        const latestTimestamp = groupedMap[dateKey][0].createdAt;
                        return {date: dateKey, items: groupedMap[dateKey], latestTimestamp};
                    })
                    .sort((a, b) => b.latestTimestamp - a.latestTimestamp)
                    .map(({date, items}) => ({date, items}));

                setGroupedTransactions(groupedArr);
            } catch (err) {
                console.error("fetchUserHistory error:", err);
            }
        }

        fetchUserHistory();
    }, [walletAddress, refetchFlag]); // refetchFlag added as a dependency

    useEffect(() => {
        // Check if localStorage has a stored address
        // it's for email logined user
        const storedAddress = localStorage.getItem("emailUserWalletAddress");
        if (storedAddress) {
            setWalletAddress(storedAddress);
            setEmailWalletAddress(storedAddress);
        }
    }, []);
    const handleMyAssetsClick = () => {
        const token = localStorage.getItem('gatewayToken');
        if (token) {
            navigate(`/swap?token=${token}`);
        } else {
            navigate('/');
        }
    };

    function toggleExpand(txId) {
        setExpanded(prev => ({
            ...prev,
            [txId]: !prev[txId]
        }));
    }

    // --- Compute today's and yesterday's date strings in the same format as used for grouping ---
    const today = new Date();
    const todayStr = today.toLocaleDateString([], {month: 'long', day: 'numeric'});
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    const yesterdayStr = yesterday.toLocaleDateString([], {month: 'long', day: 'numeric'});
    // ---------------------------------------------------------------------------------------------

    // Function to handle the Cash Received button click
    const handleCashReceivedClick = async (tx, toCashValue, networkFeeValue) => {
        setSelectedTx(tx);
        try {
            // Fetch the current price from CoinGecko
            const today = new Date();
            const dd = String(today.getDate()).padStart(2, '0');
            const mm = String(today.getMonth() + 1).padStart(2, '0');
            const yyyy = today.getFullYear();
            const formattedDate = `${dd}-${mm}-${yyyy}`;

            const apiUrl = `https://api.coingecko.com/api/v3/coins/kirakuru/history?date=${formattedDate}&localization=false`;
            const response = await fetch(apiUrl);
            const data = await response.json();
            if (
                data &&
                data.market_data &&
                data.market_data.current_price &&
                data.market_data.current_price.usd
            ) {
                const openPrice = data.market_data.current_price.usd.toFixed(8);
                setKiraPrice(openPrice);
                // Compute the additional order details
                const orderTips = networkFeeValue / 2;
                const collateralRatio = parseFloat(tx.collateral_ratio) || 2;
                const redeemFromVal = parseFloat(tx.redeemFrom);
                const collateralRequired = redeemFromVal * collateralRatio / openPrice;
                const fulfillDeadlineDate = new Date(Date.now() + 48 * 60 * 60 * 1000);
                const fulfillDeadline = fulfillDeadlineDate.toLocaleString(); // in user's local time
                const details = {
                    cashDeliveryAmount: toCashValue,
                    orderTips,
                    fulfillDeadline,
                    collateralRequired,
                    collateralRatio
                };
                setOrderDetails(details);
                setShowCashReceivedConfirm(true);
            } else {
                throw new Error("Price data not available in API response.");
            }
        } catch (error) {
            console.error("Error fetching today's price:", error);
        }
    };
    const handleCashReceived = async () => {
        const utcTime = new Date().toISOString();
        try {
            await fetch("https://usdi-api-256611876551.europe-west1.run.app/cash_redeem/cash_received", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    txs_id: selectedTx.id,
                    cash_delivery_received_time: utcTime,
                }),
            });
            // Re-fetch the transaction history after a successful update
            setRefetchFlag(prev => prev + 1);
            showNotification('success', 'Your transaction was completed!');

        } catch (error) {
            console.error("Error updating cash received:", error);
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: '#04070D',
                minHeight: '100vh',
                width: '100vw'
            }}
        >
            {/* Left Panel */}
            <LeftPanel/>

            <div className={styles.RightPanelOuterContainer}>
                <div className={styles.rightPanel} style={{display: "flex", flexDirection: "column"}}>
                    <ConnectWalletHeader
                        toAddress="4dJ5ATt3BbJPrbYCZGLAXndi5DPaZZY9j1Sp8Hdh4ApH"
                        onWalletConnected={(publicKey) => {
                            setWalletAddress(publicKey);
                        }}
                        showPanel={showPanel}
                        setShowPanel={setShowPanel}
                        emailWallet={emailWalletAddress}
                    />

                    <div className={styles.transactionContainer}>
                        {groupedTransactions.length === 0 ? <div className={styles.transactionsTitle}></div> :
                            <div className={styles.transactionsTitle}>Transactions</div>}

                        {/*// when there is transactions, display below*/}
                        {groupedTransactions.length === 0 ? (
                            <div className={styles.noTransactionsContainer}>
                                <img
                                    className={styles.noTransactionsIcon}
                                    src={noTransactionImg}
                                    alt="No transactions icon"
                                />
                                <h2 className={styles.noTransactionsTitle}>No transactions yet</h2>
                                <p className={styles.noTransactionsDescription}>
                                    Start your journey by minting USDi. Once you perform transactions,
                                    they’ll appear here for easy tracking and management.
                                </p>
                                <button className={styles.noTransactionsButton} onClick={handleMyAssetsClick}>Mint
                                    USDi
                                </button>
                            </div>
                            // when there is transactions, display below
                        ) : (groupedTransactions.map((group) => (
                                <div key={group.date}>
                                    {/* Display "Today" or "Yesterday" if applicable */}
                                    <div className={styles.transactionsDayTitle}>
                                        {group.date === todayStr
                                            ? "Today"
                                            : group.date === yesterdayStr
                                                ? "Yesterday"
                                                : group.date}
                                    </div>

                                    {group.items.map((tx) => {
                                        // Determine arrow icon and whether the row can be expanded
                                        const arrowIcon = tx.arrowDirection === 'down' ? arrowDownSvg : arrowUpSvg;
                                        const canExpand = (tx.type.toLowerCase() === 'c2c');

                                        // Calculate "To cash" and "Network fee" values
                                        const redeemFromValue = parseFloat(tx.redeemFrom);
                                        const toCashValue = Math.floor(redeemFromValue * 0.98 * 100) / 100;
                                        const networkFeeValue = redeemFromValue - toCashValue;

                                        return (
                                            <div className={styles.transactionRowOuterContainer} key={tx.id}>
                                                {/* Main Transaction Row */}
                                                <div
                                                    className={`${styles.transactionRow} ${canExpand && expanded[tx.id] ? styles.expandedRow : ''}`}
                                                >
                                                    <img className={styles.directionArrow} src={arrowIcon}
                                                         alt="arrow"/>
                                                    <span className={styles.txType}>{tx.type}</span>
                                                    <img className={styles.uIcon} src={uIconSvg} alt="USDi icon"/>
                                                    <div className={styles.amountBox}>
                                                        <span className={styles.amount}>{tx.amount} </span>
                                                        <span className={styles.usdi}> USDi</span>

                                                    </div>
                                                    <span className={styles.to}>To:</span>
                                                    <span
                                                        className={styles.toAddress}>{shortenAddress(tx.address)}</span>
                                                    <span className={styles.time}>{tx.timeStr}</span>
                                                    <span
                                                        className={
                                                            (tx.status.toLowerCase() === 'completed' || tx.status.toLowerCase() === 'cash delivered')
                                                                ? styles.statusCompleted
                                                                : styles.statusPending
                                                        }>
                                                  {tx.status}
                                                </span>
                                                    {canExpand && (
                                                        <img
                                                            className={styles.expandIcon}
                                                            src={expanded[tx.id] ? foldSvg : expandSvg}
                                                            alt="expand/fold"
                                                            onClick={() => toggleExpand(tx.id)}
                                                            onMouseEnter={(e) => {
                                                                e.currentTarget.src = expanded[tx.id] ? foldHoverSvg : expandHoverSvg;
                                                            }}
                                                            onMouseLeave={(e) => {
                                                                e.currentTarget.src = expanded[tx.id] ? foldSvg : expandSvg;
                                                            }}
                                                        />
                                                    )}
                                                </div>

                                                {/* Expanded Section with animation */}
                                                <CSSTransition
                                                    in={canExpand && expanded[tx.id]}
                                                    timeout={300}
                                                    classNames={{
                                                        enter: styles.expandedEnter,
                                                        enterActive: styles.expandedEnterActive,
                                                        exit: styles.expandedExit,
                                                        exitActive: styles.expandedExitActive,
                                                    }}
                                                    unmountOnExit
                                                >
                                                    <div className={styles.expandedContainerOuter}>
                                                        <div className={styles.lineWrapper}>
                                                            <div className={styles.dividerLineFull}></div>
                                                        </div>

                                                        <div className={styles.expandedContainer}>
                                                            {/* Info Section */}
                                                            <div className={styles.expandedInfo}>
                                                                <div className={styles.expandedSectionTitle}>Info
                                                                </div>

                                                                <div className={styles.expandedText}>
                                                                        <span
                                                                            className={styles.fixedLabel}>Redeem from:</span>
                                                                    <span
                                                                        className={styles.fetchedData}>{tx.redeemFrom} USDi</span>
                                                                </div>

                                                                <div className={styles.expandedText}>
                                                                        <span
                                                                            className={styles.fixedLabel}>To cash:</span>
                                                                    <span
                                                                        className={styles.fetchedData}>{toCashValue.toFixed(2)} USDi</span>
                                                                </div>

                                                                <div className={styles.expandedText}>
                                                                <span
                                                                    className={styles.fixedLabel}>Transaction type:</span>
                                                                    <span
                                                                        className={styles.fetchedData}>Redeem</span>
                                                                </div>

                                                                <div className={styles.expandedText}>
                                                                    <span className={styles.fixedLabel}>Time:</span>
                                                                    <span
                                                                        className={styles.fetchedData}>{tx.dateStr}, {tx.timeStr}</span>
                                                                </div>

                                                                <div className={styles.expandedText}>
                                                                        <span
                                                                            className={styles.fixedLabel}>Network:</span>
                                                                    <span className={styles.fetchedData}>C2C</span>
                                                                </div>

                                                                <div className={styles.expandedText}>
                                                                <span
                                                                    className={styles.fixedLabel}>Network fee (2%):</span>
                                                                    <span
                                                                        className={styles.fetchedData}>{networkFeeValue.toFixed(2)} USDi</span>
                                                                </div>

                                                                <div className={styles.expandedText}>
                                                                    <span className={styles.fixedLabel}>Rate:</span>
                                                                    <span
                                                                        className={styles.fetchedData}>1 USDi = 1 USD</span>
                                                                </div>

                                                                <div className={styles.expandedText}>
                                                                    <span className={styles.fixedLabel}>ETA:</span>
                                                                    <span
                                                                        className={styles.fetchedData}>2 Days</span>
                                                                </div>
                                                                <div className={styles.dividerLineFull2}></div>

                                                                <div className={styles.expandedNote}>
                                                                    Notes: First, pay the network fee to initiate
                                                                    the
                                                                    transaction. Then, pay the remaining amount upon
                                                                    delivery.
                                                                </div>
                                                            </div>

                                                            {/* Progress Section */}
                                                            <div className={styles.expandedProgress}>
                                                                <div
                                                                    className={styles.expandedSectionTitle}>Progress
                                                                </div>
                                                                <div className={styles.progressItemContainer}>
                                                                    <div>
                                                                        {tx.usdi_released_time === "--" ? (
                                                                            <img src={timeline1Img} alt="timeline"/>
                                                                        ) : tx.cash_delivery_received_time === "--" ? (
                                                                            <img src={timeline2Img} alt="timeline"/>
                                                                        ) : (
                                                                            <img src={timeline3Img} alt="timeline"/>
                                                                        )}
                                                                    </div>

                                                                    <div
                                                                        className={styles.progressItemContainerLeft}>
                                                                        <div className={styles.progressItem}>
                                                                            Transaction Created
                                                                            <div
                                                                                className={styles.progressItemTime}>
                                                                                {tx.dateStr}, {tx.timeStr}
                                                                            </div>
                                                                        </div>
                                                                        <div className={styles.progressItem}>
                                                                            USDi Released
                                                                            <div
                                                                                className={styles.progressItemTime}>
                                                                                {tx.usdi_released_time || " "}
                                                                            </div>
                                                                        </div>
                                                                        <div className={styles.progressItem}>
                                                                            Cash Delivered
                                                                            <div
                                                                                className={styles.progressItemTime}>
                                                                                {tx.cash_delivery_received_time || " "}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className={styles.progressItemContainerRight}>
                                                                        <div
                                                                            className={styles.operatorWalletContainer}>
                                                                            <div
                                                                                className={styles.operatorWalletContainerLeft}>
                                                                                <img src={greyCircleSvg} alt="O"/>
                                                                                <div className={styles.c2cWallet}>
                                                                                    {tx.address ? shortenAddress(tx.address) : " "}
                                                                                    <div
                                                                                        className={styles.greyWalletAddress}>C2C
                                                                                        Ex Wallet
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className={styles.imgContainer}>
                                                                                <img
                                                                                    style={{
                                                                                        width: "9.49px",
                                                                                        height: "11.88px"
                                                                                    }}
                                                                                    src={copyImg}
                                                                                    alt="copy img"
                                                                                    onMouseEnter={(e) => {
                                                                                        e.currentTarget.src = copyHoverImg;
                                                                                    }}
                                                                                    onMouseLeave={(e) => {
                                                                                        if (!e.currentTarget.getAttribute("data-copied")) {
                                                                                            e.currentTarget.src = copyImg;
                                                                                        }
                                                                                    }}
                                                                                    onClick={(e) => {
                                                                                        const imgElement = e.currentTarget;
                                                                                        imgElement.setAttribute("data-copied", "true");
                                                                                        navigator.clipboard.writeText(tx.address)
                                                                                            .then(() => {
                                                                                                imgElement.src = copyConfirmImg;
                                                                                                imgElement.style.width = "10px";
                                                                                                imgElement.style.height = "10px";
                                                                                                setTimeout(() => {
                                                                                                    imgElement.src = copyImg;
                                                                                                    imgElement.removeAttribute("data-copied");
                                                                                                }, 3000);
                                                                                            })
                                                                                            .catch(err => console.error("Copy failed:", err));
                                                                                    }}
                                                                                />
                                                                                <img
                                                                                    src={shareImg}
                                                                                    alt="share img"
                                                                                    onMouseEnter={(e) => {
                                                                                        e.currentTarget.src = shareHoverImg;
                                                                                    }}
                                                                                    onMouseLeave={(e) => {
                                                                                        e.currentTarget.src = shareImg;
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        window.open(`https://solscan.io/account/${tx.address}`, '_blank');
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div
                                                                            className={styles.operatorWalletContainer}>
                                                                            <div
                                                                                className={styles.operatorWalletContainerLeft}>
                                                                                <img src={greyCircleSvg} alt="O"/>
                                                                                <div className={styles.c2cWallet}>
                                                                                    {tx.node_operator_wallet ? shortenAddress(tx.node_operator_wallet) : "--"}
                                                                                    <div
                                                                                        className={styles.greyWalletAddress}>Node
                                                                                        Operator Wallet
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {tx.node_operator_wallet ?
                                                                                <div
                                                                                    className={styles.imgContainer}>
                                                                                    <img
                                                                                        style={{
                                                                                            width: "9.49px",
                                                                                            height: "11.88px"
                                                                                        }}
                                                                                        src={copyImg}
                                                                                        alt="copy img"
                                                                                        onMouseEnter={(e) => {
                                                                                            e.currentTarget.src = copyHoverImg;
                                                                                        }}
                                                                                        onMouseLeave={(e) => {
                                                                                            if (!e.currentTarget.getAttribute("data-copied")) {
                                                                                                e.currentTarget.src = copyImg;
                                                                                            }
                                                                                        }}
                                                                                        onClick={(e) => {
                                                                                            const imgElement = e.currentTarget;
                                                                                            imgElement.setAttribute("data-copied", "true");
                                                                                            navigator.clipboard.writeText(tx.node_operator_wallet)
                                                                                                .then(() => {
                                                                                                    imgElement.src = copyConfirmImg;
                                                                                                    imgElement.style.width = "10px";
                                                                                                    imgElement.style.height = "10px";
                                                                                                    setTimeout(() => {
                                                                                                        imgElement.src = copyImg;
                                                                                                        imgElement.removeAttribute("data-copied");
                                                                                                    }, 3000);
                                                                                                })
                                                                                                .catch(err => console.error("Copy failed:", err));
                                                                                        }}
                                                                                    />
                                                                                    <img
                                                                                        src={shareImg}
                                                                                        alt="share img"
                                                                                        onMouseEnter={(e) => {
                                                                                            e.currentTarget.src = shareHoverImg;
                                                                                        }}
                                                                                        onMouseLeave={(e) => {
                                                                                            e.currentTarget.src = shareImg;
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            window.open(`https://solscan.io/account/${tx.node_operator_wallet}`, '_blank');
                                                                                        }}
                                                                                    />
                                                                                </div> : <div></div>}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {tx.status.toLowerCase() !== 'completed' ? (
                                                                    <div className={styles.progressButtons}>
                                                                        {tx.usdi_released_time === "--" ? (
                                                                            <>
                                                                                <button
                                                                                    className={styles.progressButton}>Cancel
                                                                                </button>
                                                                                <button
                                                                                    className={styles.progressButton2}
                                                                                    onClick={() => handleCashReceivedClick(tx, toCashValue, networkFeeValue)}
                                                                                >
                                                                                    Cash Received
                                                                                </button>
                                                                            </>
                                                                        ) : tx.address.toLowerCase() !== walletAddress.toLowerCase() ? (
                                                                            // 如果 tx.address !== walletAddress，则显示 Settle Funds Now 按钮
                                                                            <button
                                                                                className={styles.progressButton3}>Settle
                                                                                Funds Now</button>
                                                                        ) : (
                                                                            <>
                                                                                <button
                                                                                    className={styles.progressButton}>Dispute
                                                                                </button>
                                                                                <button
                                                                                    className={styles.progressButton2}
                                                                                    onClick={() => handleCashReceivedClick(tx, toCashValue, networkFeeValue)}
                                                                                >
                                                                                    Cash Received
                                                                                </button>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    <div></div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </CSSTransition>
                                            </div>
                                        );
                                    })}
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>

            {showCashReceivedConfirm && orderDetails && (
                <C2COrderDetail
                    cashDeliveryAmount={`${(Math.ceil(orderDetails.cashDeliveryAmount * 100) / 100).toFixed(2)} USDi`}
                    orderTips={`${(orderDetails.orderTips / kiraPrice).toFixed(2)} KRA ($${(Math.floor(orderDetails.orderTips * 100) / 100).toFixed(2)})`}
                    fulfillDeadline={orderDetails.fulfillDeadline}
                    collateralRequired={`${(Math.ceil(orderDetails.collateralRequired * 100) / 100).toFixed(2)} KRA`}
                    rate={kiraPrice}
                    collateralRatio={`${(orderDetails.collateralRatio * 100).toFixed(0)}%`}
                    onConfirm={async () => {
                        try {
                            await handleCashReceived();
                            setShowCashReceivedConfirm(false);
                        } catch (error) {
                            console.error("Error in handleCashReceived:", error);
                        }
                    }}
                    onClose={() => {
                        setShowCashReceivedConfirm(false);
                        setOrderDetails(null);
                    }}
                    isFulfillOrder={false}
                />

            )}
        </div>
    );
}

export default TransactionHistory;

import React, {useContext, useEffect, useState} from 'react';
import styles from './C2CNetwork.module.css';

import ConnectWalletHeader from "../header/ConnectWalletHeader";
import LeftPanel from "../SwapLeftPanel/LeftPanel";

// Icons
import arrowUpSvg from '../static_files/Vector Up.svg';
import arrowDownSvg from '../static_files/Vector Down.svg';
import expandSvg from "../static_files/expand.svg";
import foldSvg from "../static_files/unexpand.svg";
import greyCircleSvg from '../static_files/circle.svg';
import expandHoverSvg from "../static_files/expandHover.svg";
import foldHoverSvg from "../static_files/unexpandHover.svg";
import uIconSvg from "../static_files/USDi.png";
import copyImg from "../static_files/copy-selector.svg";
import copyHoverImg from "../static_files/copy-selector-hover.svg";
import copyConfirmImg from "../static_files/checkmark.png";
import shareImg from "../static_files/share-selector.svg";
import shareHoverImg from "../static_files/share-selector-hover.svg";
import timeline1Img from "../static_files/timeline1.svg";
import timeline2Img from "../static_files/timeline2.svg";
import timeline3Img from "../static_files/timeline3.svg";


import {shortenAddress, formatAmount} from '../utils';

// Import CSSTransition for animation
import {CSSTransition} from 'react-transition-group';
import C2COrderDetail from "./C2COrderDetail";
import {SwapMainAppContext} from "../../SwapMainAppContext";

function C2CNetwork() {
    const [walletAddress, setWalletAddress] = useState("");
    const [showPanel, setShowPanel] = useState(false);
    const [groupedTransactions, setGroupedTransactions] = useState([]);
    const [expanded, setExpanded] = useState({});

    // New state for fulfilling the order and storing the selected row's details
    const [showFulfill, setShowFulfill] = useState(false);
    const [kiraPrice, setKiraPrice] = useState(null);
    const [selectedTx, setSelectedTx] = useState(null);
    const [orderDetails, setOrderDetails] = useState(null);

    // for refreshing
    const [refetchFlag, setRefetchFlag] = useState(0);

    // passed into connectWalletHeader
    const [txTrigger, setTxTrigger] = useState(0);
    const [sendKiraAmount, setSendKiraAmount] = useState(0);
    //email 登录的时候传给connect wallet header
    const [emailWalletAddress, setEmailWalletAddress] = useState(null);

    const {showNotification, networkFeePercent, collateralRatio} = useContext(SwapMainAppContext);

    useEffect(() => {
        // Check if localStorage has a stored address
        // it's for email logined user
        const storedAddress = localStorage.getItem("emailUserWalletAddress");
        if (storedAddress) {
            setWalletAddress(storedAddress);
            setEmailWalletAddress(storedAddress)
        }
    }, []);
    // Fetch and group transactions by date
    useEffect(() => {
        if (!walletAddress) return;

        // Helper function to convert an ISO date string to local time in the desired format
        const formatLocalDate = (dateStr) => {
            const dateObj = new Date(dateStr);
            if (isNaN(dateObj.getTime())) return dateStr;
            return dateObj.toLocaleString([], {
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: '2-digit',
                hour12: true,
            });
        };

        // 从 user transaction history 获取 data 并且 process the data
        async function fetchUserHistory() {
            try {
                const url = `https://usdi-api-256611876551.europe-west1.run.app/cash_redeem/get_user_history`;
                const res = await fetch(url);
                const data = await res.json();

                if (!data || !Array.isArray(data.transaction_history)) {
                    console.warn("No valid transaction_history returned");
                    setGroupedTransactions([]);
                    return;
                }

                const cashRedeemData = Array.isArray(data.cash_redeem) ? data.cash_redeem : [];

                const allTxs = data.transaction_history.map((tx) => {
                    const createdAt = new Date(tx.txs_created_timestamp + 'Z');
                    const timeStr = createdAt.toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                    });
                    const dateStr = createdAt.toLocaleDateString([], {month: 'long', day: 'numeric'});
                    const arrowDirection = tx.txs_type?.toLowerCase() === 'mint' ? 'down' : 'up';
                    // If redeem with method cash, display as "C2C"
                    let displayType = tx.txs_type;
                    if (tx.txs_type?.toLowerCase() === 'redeem' && tx.method?.toLowerCase() === 'cash') {
                        displayType = 'C2C';
                    }

                    // Build the initial transaction object
                    const txObj = {
                        id: tx.txs_id,
                        arrowDirection,
                        type: displayType,
                        amount: formatAmount(tx.from_token_amount || 0),
                        address: tx.from_address || '',
                        chain: tx.chain?.toLowerCase() || '',
                        redeemFrom: formatAmount(tx.from_token_amount || 0),
                        timeStr,
                        dateStr,
                        createdAt, // used for sorting
                        cash_delivery_received_time: "--",
                        usdi_released_time: "--",
                        status: "--",
                        fulfill_deadline: tx.fulfill_deadline || "--",
                        collateral_ratio: collateralRatio / 100,
                    };

                    // Find the matching cash_redeem record by txs_id
                    const matchingRedeem = cashRedeemData.find((redeem) => redeem.txs_id === tx.txs_id);
                    if (matchingRedeem) {
                        txObj.usdi_released_time = matchingRedeem.usdi_released_time
                            ? formatLocalDate(matchingRedeem.usdi_released_time)
                            : "--";
                        txObj.cash_delivery_received_time = matchingRedeem.cash_delivery_received_time
                            ? formatLocalDate(matchingRedeem.cash_delivery_received_time)
                            : "--";
                        // 使用 cash_redeem 里的 status
                        txObj.status = matchingRedeem.status || "--";
                        txObj.node_operator_wallet = matchingRedeem.node_operator_wallet;
                    }

                    return txObj;
                });

                // Group transactions by the formatted date string
                const groupedMap = {};
                allTxs.forEach((tx) => {
                    if (!groupedMap[tx.dateStr]) {
                        groupedMap[tx.dateStr] = [];
                    }
                    groupedMap[tx.dateStr].push(tx);
                });

                // Sort transactions within each day (newest at the top)
                Object.keys(groupedMap).forEach((dateKey) => {
                    groupedMap[dateKey].sort((a, b) => b.createdAt - a.createdAt);
                });

                // 转成数组并按照最新日期排序
                const groupedArr = Object.keys(groupedMap)
                    .map((dateKey) => {
                        // 用每组第一个（最新）交易的 createdAt 做代表
                        const latestTimestamp = groupedMap[dateKey][0].createdAt;
                        return {date: dateKey, items: groupedMap[dateKey], latestTimestamp};
                    })
                    .sort((a, b) => b.latestTimestamp - a.latestTimestamp)
                    .map(({date, items}) => ({date, items}));

                setGroupedTransactions(groupedArr);
            } catch (err) {
                console.error("fetchUserHistory error:", err);
            }
        }

        fetchUserHistory();
    }, [walletAddress, refetchFlag]);

    function toggleExpand(txId) {
        setExpanded(prev => ({
            ...prev,
            [txId]: !prev[txId]
        }));
    }

    // fulfill order 的逻辑
    const handleFulfillClick = async (tx, toCashValue, networkFeeValue) => {
        setSelectedTx(tx);
        try {
            // 获取当天 KIRA 价格
            const today = new Date();
            const dd = String(today.getDate()).padStart(2, '0');
            const mm = String(today.getMonth() + 1).padStart(2, '0');
            const yyyy = today.getFullYear();
            const formattedDate = `${dd}-${mm}-${yyyy}`;

            const apiUrl = `https://api.coingecko.com/api/v3/coins/kirakuru/history?date=${formattedDate}&localization=false`;
            const response = await fetch(apiUrl);
            const data = await response.json();
            if (
                data &&
                data.market_data &&
                data.market_data.current_price &&
                data.market_data.current_price.usd
            ) {
                const openPrice = data.market_data.current_price.usd.toFixed(8);
                setKiraPrice(openPrice);

                // 额外的订单细节
                const orderTips = networkFeeValue / 2;
                const collateralRatio = parseFloat(tx.collateral_ratio) || 2;
                const redeemFromVal = parseFloat(tx.redeemFrom);
                const collateralRequired = redeemFromVal * collateralRatio / openPrice;
                const fulfillDeadlineDate = new Date(Date.now() + 48 * 60 * 60 * 1000);
                const fulfillDeadline = fulfillDeadlineDate.toLocaleString(); // local time
                const details = {
                    cashDeliveryAmount: toCashValue,
                    orderTips,
                    fulfillDeadline,
                    collateralRequired,
                    collateralRatio
                };
                setOrderDetails(details);
                setShowFulfill(true);
            } else {
                throw new Error("Price data not available in API response.");
            }
        } catch (error) {
            console.error("Error fetching today's price:", error);
        }
    };

    // 当 KIRA 被发送后会触发此函数
    const handleKiraSent = (data) => {
        if (!selectedTx || !orderDetails || !kiraPrice) {
            console.error("Missing selected transaction, order details, or KRA rate.");
            return;
        }

        // 获取当前 UTC 时间 和 48小时之后的 UTC 时间
        const nowUtc = new Date().toISOString();
        const deadlineUtc = new Date(Date.now() + 48 * 60 * 60 * 1000).toISOString();

        // 拼装需要传给后端的 payload
        const payload = {
            txs_id: selectedTx.id,
            node_operator_wallet: data.from,
            kra_rate: kiraPrice,
            collateral_amount: sendKiraAmount,
            order_tips: (
                Math.floor((orderDetails.orderTips / kiraPrice) * 100) / 100
            ).toFixed(2),
            order_tips_dollar_value: (Math.floor(orderDetails.orderTips * 100) / 100).toFixed(2),
            usdi_released_time: nowUtc,
            fulfill_deadline: deadlineUtc,
            transaction_signature: data.signature,
            cash_delivery_amount: (Math.floor(selectedTx.amount * (100 - networkFeePercent)) / 100)
        };
        console.log("payload", payload)
        fetch("https://usdi-api-256611876551.europe-west1.run.app/cash_redeem/fulfill_order", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(payload),
        })
            .then((res) => res.json())
            .then((result) => {
                console.log("API result:", result);

                // 强制刷新交易记录
                setRefetchFlag(prev => prev + 1);

                showNotification('success', 'You have been assigned to this order!');
            })
            .catch((error) => {
                console.error("API error:", error);
                showNotification('error', `FulFill Order failed: ${error.message}`);
            });
    };


    // --- 计算 "Today" & "Yesterday" 用的 date string ---
    const today = new Date();
    const todayStr = today.toLocaleDateString([], {month: 'long', day: 'numeric'});
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    const yesterdayStr = yesterday.toLocaleDateString([], {month: 'long', day: 'numeric'});
    // ----------------------------------------------------

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: '#04070D',
                minHeight: '100vh',
                width: '100vw'
            }}
        >
            {/* Left Panel */}
            <LeftPanel/>

            <div className={styles.RightPanelOuterContainer}>
                <div className={styles.rightPanel} style={{display: "flex", flexDirection: "column"}}>
                    <ConnectWalletHeader
                        amount={sendKiraAmount}
                        transactionTrigger={txTrigger}
                        selectedToken="KIRA"
                        transactionType="Redeem"
                        onWalletConnected={(publicKey) => {
                            setWalletAddress(publicKey);
                        }}
                        showPanel={showPanel}
                        setShowPanel={setShowPanel}
                        onKiraSent={handleKiraSent} // <-- 传入回调
                        //email login 的时候传入
                        emailWallet={emailWalletAddress}

                    />

                    <div className={styles.transactionContainer}>
                        <div className={styles.transactionsTitle}>C2C Network</div>

                        {/** 只显示 status === 'order_created' 的交易记录 */}
                        {groupedTransactions.map((group) => {
                            // 1. 筛选出 status 为 "order_created" 的交易
                            const filteredItems = group.items.filter(
                                (tx) => tx.status?.toLowerCase() === 'order_created'
                            );
                            // 2. 若无符合条件的交易，直接 return null 不渲染
                            if (filteredItems.length === 0) {
                                return null;
                            }

                            return (
                                <div key={group.date}>
                                    <div className={styles.transactionsDayTitle}>
                                        {group.date === todayStr
                                            ? "Today"
                                            : group.date === yesterdayStr
                                                ? "Yesterday"
                                                : group.date}
                                    </div>

                                    {/* 3. 用 filteredItems.map() 生成 UI */}
                                    {filteredItems.map((tx) => {
                                        const arrowIcon = tx.arrowDirection === 'down' ? arrowDownSvg : arrowUpSvg;
                                        const canExpand = (tx.type.toLowerCase() === 'c2c');

                                        const redeemFromValue = parseFloat(tx.redeemFrom);
                                        const toCashValue = Math.floor(redeemFromValue * (100 - networkFeePercent)) / 100;
                                        const networkFeeValue = redeemFromValue - toCashValue;

                                        return (
                                            <div className={styles.transactionRowOuterContainer} key={tx.id}>
                                                {/* Main Transaction Row */}
                                                <div
                                                    className={`${styles.transactionRow} ${canExpand && expanded[tx.id] ? styles.expandedRow : ''}`}>
                                                    <img className={styles.directionArrow} src={arrowIcon} alt="arrow"/>
                                                    <span className={styles.txType}>{tx.type}</span>
                                                    <img className={styles.uIcon} src={uIconSvg} alt="USDi icon"/>
                                                    <div className={styles.amountBox}>
                                                        <span className={styles.amount}>{tx.amount} </span>
                                                        <span className={styles.usdi}> USDi</span>

                                                    </div>
                                                    <span className={styles.to}>To:</span>
                                                    <span
                                                        className={styles.toAddress}>{shortenAddress(tx.address)}</span>
                                                    <span className={styles.time}>{tx.timeStr}</span>
                                                    <span
                                                        className={
                                                            tx.status.toLowerCase() === 'order_created'
                                                                ? styles.statusPending
                                                                : styles.statusCompleted
                                                        }
                                                    >
                                                        {
                                                            tx.status.toLowerCase() === 'order_created'
                                                                ? 'Order Created'
                                                                : tx.status.toLowerCase() === 'order_fulfilled' || tx.status.toLowerCase() === 'order-fulfilled'
                                                                    ? 'USDi Released'
                                                                    : tx.status.toLowerCase() === 'cash_received'
                                                                        ? 'Cash Delivered'
                                                                        : tx.status
                                                        }
                                                    </span>
                                                    {canExpand && (
                                                        <img
                                                            className={styles.expandIcon}
                                                            src={expanded[tx.id] ? foldSvg : expandSvg}
                                                            alt="expand/fold"
                                                            onClick={() => toggleExpand(tx.id)}
                                                            onMouseEnter={(e) => {
                                                                e.currentTarget.src = expanded[tx.id] ? foldHoverSvg : expandHoverSvg;
                                                            }}
                                                            onMouseLeave={(e) => {
                                                                e.currentTarget.src = expanded[tx.id] ? foldSvg : expandSvg;
                                                            }}
                                                        />
                                                    )}
                                                </div>

                                                {/* Expanded Section with animation */}
                                                <CSSTransition
                                                    in={canExpand && expanded[tx.id]}
                                                    timeout={300}
                                                    classNames={{
                                                        enter: styles.expandedEnter,
                                                        enterActive: styles.expandedEnterActive,
                                                        exit: styles.expandedExit,
                                                        exitActive: styles.expandedExitActive,
                                                    }}
                                                    unmountOnExit
                                                >
                                                    <div className={styles.expandedContainerOuter}>
                                                        <div className={styles.lineWrapper}>
                                                            <div className={styles.dividerLineFull}></div>
                                                        </div>

                                                        <div className={styles.expandedContainer}>
                                                            {/* Info Section */}
                                                            <div className={styles.expandedInfo}>
                                                                <div className={styles.expandedSectionTitle}>Info</div>

                                                                <div className={styles.expandedText}>
                                                                    <span
                                                                        className={styles.fixedLabel}>Redeem from:</span>
                                                                    <span className={styles.fetchedData}>
                                                                        {tx.redeemFrom} USDi
                                                                    </span>
                                                                </div>

                                                                <div className={styles.expandedText}>
                                                                    <span className={styles.fixedLabel}>To cash:</span>
                                                                    <span className={styles.fetchedData}>
                                                                        {toCashValue.toFixed(2)} USDi
                                                                    </span>
                                                                </div>

                                                                <div className={styles.expandedText}>
                                                                    <span className={styles.fixedLabel}>Transaction type:</span>
                                                                    <span className={styles.fetchedData}>Redeem</span>
                                                                </div>

                                                                <div className={styles.expandedText}>
                                                                    <span className={styles.fixedLabel}>Time:</span>
                                                                    <span className={styles.fetchedData}>
                                                                        {tx.dateStr}, {tx.timeStr}
                                                                    </span>
                                                                </div>

                                                                <div className={styles.expandedText}>
                                                                    <span className={styles.fixedLabel}>Network:</span>
                                                                    <span className={styles.fetchedData}>C2C</span>
                                                                </div>

                                                                <div className={styles.expandedText}>
                                                                    <span className={styles.fixedLabel}>Network fee (2%):</span>
                                                                    <span className={styles.fetchedData}>
                                                                        {networkFeeValue.toFixed(2)} USDi
                                                                    </span>
                                                                </div>

                                                                <div className={styles.expandedText}>
                                                                    <span className={styles.fixedLabel}>Rate:</span>
                                                                    <span
                                                                        className={styles.fetchedData}>1 USDi = 1 USD</span>
                                                                </div>

                                                                <div className={styles.expandedText}>
                                                                    <span className={styles.fixedLabel}>ETA:</span>
                                                                    <span className={styles.fetchedData}>2 Days</span>
                                                                </div>
                                                                <div className={styles.dividerLineFull2}></div>

                                                                <div className={styles.expandedNote}>
                                                                    Notes: First, pay the network fee to initiate the
                                                                    transaction. Then, pay the remaining amount upon
                                                                    delivery.
                                                                </div>
                                                            </div>

                                                            {/* Progress Section */}
                                                            <div className={styles.expandedProgress}>
                                                                <div className={styles.expandedSectionTitle}>Progress
                                                                </div>
                                                                <div className={styles.progressItemContainer}>
                                                                    <div>
                                                                        {tx.usdi_released_time === "--" ? (
                                                                            <img src={timeline1Img} alt="timeline"/>
                                                                        ) : tx.cash_delivery_received_time === "--" ? (
                                                                            <img src={timeline2Img} alt="timeline"/>
                                                                        ) : (
                                                                            <img src={timeline3Img} alt="timeline"/>
                                                                        )}
                                                                    </div>

                                                                    <div className={styles.progressItemContainerLeft}>
                                                                        <div className={styles.progressItem}>
                                                                            Transaction Created
                                                                            <div className={styles.progressItemTime}>
                                                                                {tx.dateStr}, {tx.timeStr}
                                                                            </div>
                                                                        </div>
                                                                        <div className={styles.progressItem}>
                                                                            USDi Released
                                                                            <div className={styles.progressItemTime}>
                                                                                {tx.usdi_released_time || " "}
                                                                            </div>
                                                                        </div>
                                                                        <div className={styles.progressItem}>
                                                                            Cash Delivered
                                                                            <div className={styles.progressItemTime}>
                                                                                {tx.cash_delivery_received_time || " "}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className={styles.progressItemContainerRight}>
                                                                        <div className={styles.operatorWalletContainer}>
                                                                            <div
                                                                                className={styles.operatorWalletContainerLeft}>
                                                                                <img src={greyCircleSvg} alt="O"/>
                                                                                <div className={styles.c2cWallet}>
                                                                                    {tx.address ? shortenAddress(tx.address) : " "}
                                                                                    <div
                                                                                        className={styles.greyWalletAddress}>
                                                                                        C2C Ex Wallet
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className={styles.imgContainer}>
                                                                                <img
                                                                                    style={{
                                                                                        width: "9.49px",
                                                                                        height: "11.88px"
                                                                                    }}
                                                                                    src={copyImg}
                                                                                    alt="copy img"
                                                                                    onMouseEnter={(e) => {
                                                                                        e.currentTarget.src = copyHoverImg;
                                                                                    }}
                                                                                    onMouseLeave={(e) => {
                                                                                        if (!e.currentTarget.getAttribute("data-copied")) {
                                                                                            e.currentTarget.src = copyImg;
                                                                                        }
                                                                                    }}
                                                                                    onClick={(e) => {
                                                                                        const imgElement = e.currentTarget;
                                                                                        imgElement.setAttribute("data-copied", "true");
                                                                                        navigator.clipboard.writeText(tx.address)
                                                                                            .then(() => {
                                                                                                imgElement.src = copyConfirmImg;
                                                                                                imgElement.style.width = "10px";
                                                                                                imgElement.style.height = "10px";
                                                                                                setTimeout(() => {
                                                                                                    imgElement.src = copyImg;
                                                                                                    imgElement.removeAttribute("data-copied");
                                                                                                }, 3000);
                                                                                            })
                                                                                            .catch(err => console.error("Copy failed:", err));
                                                                                    }}
                                                                                />
                                                                                <img
                                                                                    src={shareImg}
                                                                                    alt="share img"
                                                                                    onMouseEnter={(e) => {
                                                                                        e.currentTarget.src = shareHoverImg;
                                                                                    }}
                                                                                    onMouseLeave={(e) => {
                                                                                        e.currentTarget.src = shareImg;
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        window.open(`https://solscan.io/account/${tx.address}`, '_blank');
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className={styles.operatorWalletContainer}>
                                                                            <div
                                                                                className={styles.operatorWalletContainerLeft}>
                                                                                <img src={greyCircleSvg} alt="O"/>
                                                                                <div className={styles.c2cWallet}>
                                                                                    {tx.node_operator_wallet ? shortenAddress(tx.node_operator_wallet) : "--"}
                                                                                    <div
                                                                                        className={styles.greyWalletAddress}>
                                                                                        Node Operator Wallet
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {tx.node_operator_wallet ? (
                                                                                <div className={styles.imgContainer}>
                                                                                    <img
                                                                                        style={{
                                                                                            width: "9.49px",
                                                                                            height: "11.88px"
                                                                                        }}
                                                                                        src={copyImg}
                                                                                        alt="copy img"
                                                                                        onMouseEnter={(e) => {
                                                                                            e.currentTarget.src = copyHoverImg;
                                                                                        }}
                                                                                        onMouseLeave={(e) => {
                                                                                            if (!e.currentTarget.getAttribute("data-copied")) {
                                                                                                e.currentTarget.src = copyImg;
                                                                                            }
                                                                                        }}
                                                                                        onClick={(e) => {
                                                                                            const imgElement = e.currentTarget;
                                                                                            imgElement.setAttribute("data-copied", "true");
                                                                                            navigator.clipboard.writeText(tx.node_operator_wallet)
                                                                                                .then(() => {
                                                                                                    imgElement.src = copyConfirmImg;
                                                                                                    imgElement.style.width = "10px";
                                                                                                    imgElement.style.height = "10px";
                                                                                                    setTimeout(() => {
                                                                                                        imgElement.src = copyImg;
                                                                                                        imgElement.removeAttribute("data-copied");
                                                                                                    }, 3000);
                                                                                                })
                                                                                                .catch(err => console.error("Copy failed:", err));
                                                                                        }}
                                                                                    />
                                                                                    <img
                                                                                        src={shareImg}
                                                                                        alt="share img"
                                                                                        onMouseEnter={(e) => {
                                                                                            e.currentTarget.src = shareHoverImg;
                                                                                        }}
                                                                                        onMouseLeave={(e) => {
                                                                                            e.currentTarget.src = shareImg;
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            window.open(`https://solscan.io/account/${tx.node_operator_wallet}`, '_blank');
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            ) : <div></div>}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className={styles.progressButtons}>
                                                                    {tx.status.toLowerCase() === 'order_created' ? (
                                                                        <button
                                                                            className={styles.progressButton2}
                                                                            onClick={() => handleFulfillClick(tx, toCashValue, networkFeeValue)}
                                                                        >
                                                                            Fulfill the Order
                                                                        </button>
                                                                    ) : tx.cash_delivery_received_time === "--" ? (
                                                                        <button
                                                                            className={styles.progressButton3}
                                                                        >
                                                                            Settle Funds Now
                                                                        </button>
                                                                    ) : (
                                                                        <div></div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </CSSTransition>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

            {/* Render the Fulfill Order component if showFulfill is true */}
            {showFulfill && orderDetails && (
                <C2COrderDetail
                    cashDeliveryAmount={`${(Math.ceil(orderDetails.cashDeliveryAmount * 100) / 100).toFixed(2)} USDi`}
                    orderTips={`${(
                        Math.floor((orderDetails.orderTips / kiraPrice) * 100) / 100
                    ).toFixed(2)} KRA ($${
                        (Math.floor(orderDetails.orderTips * 100) / 100).toFixed(2)
                    })`}
                    fulfillDeadline={orderDetails.fulfillDeadline}
                    collateralRequired={`${(Math.ceil(orderDetails.collateralRequired * 100) / 100).toFixed(2)} KRA`}
                    rate={kiraPrice}
                    collateralRatio={`${(orderDetails.collateralRatio * 100).toFixed(0)}%`}
                    onConfirm={() => {
                        setSendKiraAmount((Math.ceil(orderDetails.collateralRequired * 100) / 100).toFixed(2));
                        setTxTrigger((prev) => prev + 1);
                        setShowFulfill(false);
                    }}
                    onClose={() => {
                        setShowFulfill(false);
                        setOrderDetails(null);
                    }}
                />
            )}
        </div>
    );
}

export default C2CNetwork;